import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from '../../../environments/environment';

@Injectable({
    providedIn: 'root',
})
export class AuthenticationService {
    constructor(private http: HttpClient) {}
    signUpAttempt = false;
    planSelect = false;
    login(user: { email: string; password: string; remember: boolean }) {
        return this.http.post<LoginResponse>(
            environment.api_url + 'api/login',
            user
        );
    }
    resetPassword(postVal: {
        email: string;
        token: string;
        password: string;
        password_confirmation: string;
    }) {
        return this.http.post<ResetPasswordResponse>(
            environment.api_url + 'api/reset-password',
            postVal
        );
    }
    forgetPassword(postVal: { email: string }) {
        return this.http.post<ForgetPasswordResponse>(
            environment.api_url + 'api/forgot-password',
            postVal
        );
    }

    signup(postVal: {
        emailAddress: string;
        password: string;
        firstName: string;
        lastName: string;
        supplierId: number | null;
    }) {
        return this.http.post<RegisterResponse>(
            environment.api_url + 'api/register',
            postVal
        );
    }

    refreshToken(postVal: { refresh_token: string }) {
        return this.http.post<LoginResponse>(
            environment.api_url + 'api/refresh-token',
            postVal
        );
    }

    logout() {
        return this.http.post<LogoutResponse>(
            environment.api_url + 'api/logout',
            {}
        );
    }

    verifyAutoLogin() {
        return this.http.get<any>(
            environment.api_url + 'api/client-auto-login',
        );
    }
}

interface LoginResponse {
    success: boolean;
    data: Data;
    message?: string;
}

interface ResetPasswordResponse {
    success: boolean;
    message?: string;
}

interface ForgetPasswordResponse {
    status: boolean;
    message?: string;
}

interface RegisterResponse {
    success: boolean;
    data: Data;
    message?: string;
}

interface LogoutResponse {
    success: boolean;
    message?: string;
}

interface Data {
    user: User;
    token: string;
    refreshToken?: string;
}

export interface User {
    profileImage: string;
    id: number;
    clientId: number;
    firstName: string;
    lastName: string;
    emailAddress: string;
    email_verified_at: string;
    created_at: string;
    updated_at: string;
    status: string;
    allocationType: string;
    isAdmin: string;
    viewProductPrice: string;
}
